<style lang="scss">
#overview {
  $margin: 100px;
  .row.headline {
    margin-bottom: $margin;
  }

  .asterisc {
    text-align: center;
    margin-top: 150px;
  }

  .skillcontainer {
    text-align: center;
    // cursor: pointer;

    .skill {
      display: inline-block;
      text-align: center;
      padding: 10px 0px;
      border-radius: 0px;
      margin: 20px 30px;
      //box-shadow: 0 0 8px rgba(0, 0, 0, 0.5);
      color: red;

      .percent {
        font-weight: bold;
        font-size: 140%;
        margin-right: 5px;
      }
      // .name {
      //   font-weight: bold;
      // }
    }
    .neuentwicklung {
      border-bottom:2px solid $c-neuentwicklung;
    }
    .umsetzung {
      border-bottom:2px solid $c-umsetzung;
    }
    .sonstige {
      border-bottom:2px solid $c-sonstige;
    }
  }

  .categoryheader {
    margin-top: 120px;
  }

  .linecontainer {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    .line {
      display: inline-block;
      height: 75px;
      width: 1px;
      border-left: 1px solid red;
      position: relative;

      &.neuentwicklung {
        border-color: $c-neuentwicklung;
      }
      &.umsetzung {
        border-color: $c-umsetzung;
      }
      &.sonstige {
        border-color: $c-sonstige;
      }
    }
  }

  .skillsoverview {
    margin-top: $margin;
    text-align: center;
    // background-color: lightgray;
  }
}
</style>

<template>
  <div id="overview">
    <div class="row headline">
      <h1 class="col-12">DIE SKILLS</h1>
      <h2 class="col-12">
        der Styria Media Design<br />
        im Überblick*
      </h2>
    </div>
    <div class="row description justify-content-center">
      <div class="col-6">
        Die hier dargestellten Skills wurden gemeinsam in der Styria Media Design ermittelt bzw. definiert.<br />
        Die Entwicklung erfolgte auf Basis von bereits gut entwickelten bzw. vorhandenen Fähigkeiten als auch von Skills, die derzeit<br />
        keinen Schwerpunkt in der Styria Media Design haben, aber potenziell für Kunden notwendig sein können<br />bzw. gefordert werden.
      </div>
    </div>
    <CategoryHeader />

    <div class="row justify-content-center linecontainer" v-if="openfilter != ''">
      <div class="col-2">
        <div class="line neuentwicklung" v-if="openfilter == 'Neuentwicklung'"></div>
      </div>
      <div class="col-2">
        <div class="line umsetzung" v-if="openfilter == 'Umsetzung'"></div>
      </div>
      <div class="col-2">
        <div class="line sonstige" v-if="openfilter == 'Sonstige'"></div>
      </div>
    </div>

    <div class="row justify-content-center">
      <div class="col-6 skillcontainer">
        <div v-for="skill in filteredskills" class="skill" :style="`color:${skillscolor}`" :class="skillsname">
          <span class="percent">{{ skill.percent }}%</span> <span class="name">{{ skill.name }}</span>
        </div>
      </div>
    </div>

    <div class="row asterisc justify-content-center">
      <div class="col-12">
        *Die Skills wurden in jeweils 2 Dimensionen abgefragt bzw. bewertet, z.B. Kreativität und Konzeption oder Methodik und Kenntnisse der Tools.<br />
        Die Punkte in den beiden Dimensionen wurden zu einer Gesamtpunktezahl addiert und werden im Verhältnis zur maximal erreichbaren Punktezahl dargestellt.
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, inject, provide, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CategoryHeader from '../../components/CategoryHeader.vue';

export default defineComponent({
  components: {
    CategoryHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const open = ref(false);
    const openfilter = ref('');
    

    // Infoboxes
    const infoboxes = ref({ teams: 0, mitarbeiter: 0, skills: 0 });
    store.dispatch('fetchMeta').then((res) => {
      infoboxes.value = res;
    });
    // Infoboxes END

    // Charts
    const skills = ref([]);
    store.dispatch('fetchSkills').then((res) => {
      skills.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
    });

    const filteredskills = computed(() => {
      return skills.value.filter((skill: any) => skill.group == openfilter.value);
    });

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
    };

    provide('onOpen', (filter: string) => {
      open.value = true;
      openfilter.value = filter;
    });

    const skillscolor = computed(() => {
      switch (openfilter.value) {
        case 'Neuentwicklung':
          return '#cb5f63';
        case 'Umsetzung':
          return '#71a0cd';
        case 'Sonstige':
          return '#3c97a0';
      }
    });
    const skillsname = computed(() => {
      switch (openfilter.value) {
        case 'Neuentwicklung':
          return 'neuentwicklung';
        case 'Umsetzung':
          return 'umsetzung';
        case 'Sonstige':
          return 'sonstige';
      }
    });

    return {
      goToSkill,
      skills,
      infoboxes,
      skillscolor,
      skillsname,
      filteredskills,
      openfilter,
    };
  },
});
</script>
