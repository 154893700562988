
import { computed, defineComponent, inject, provide, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';

import CategoryHeader from '../../components/CategoryHeader.vue';

export default defineComponent({
  components: {
    CategoryHeader,
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const open = ref(false);
    const openfilter = ref('');
    

    // Infoboxes
    const infoboxes = ref({ teams: 0, mitarbeiter: 0, skills: 0 });
    store.dispatch('fetchMeta').then((res) => {
      infoboxes.value = res;
    });
    // Infoboxes END

    // Charts
    const skills = ref([]);
    store.dispatch('fetchSkills').then((res) => {
      skills.value = res
        .map((skill: any) => {
          skill.percent = Math.ceil((skill.reached / skill.max) * 100);
          return skill;
        })
        .sort((a: any, b: any) => {
          if (a.percent > b.percent) return -1;
          if (a.percent < b.percent) return 1;

          return 0;
        });
    });

    const filteredskills = computed(() => {
      return skills.value.filter((skill: any) => skill.group == openfilter.value);
    });

    const goToSkill = (skill: string) => {
      router.push({ name: 'SkillDetails', params: { id: skill } });
    };

    provide('onOpen', (filter: string) => {
      open.value = true;
      openfilter.value = filter;
    });

    const skillscolor = computed(() => {
      switch (openfilter.value) {
        case 'Neuentwicklung':
          return '#cb5f63';
        case 'Umsetzung':
          return '#71a0cd';
        case 'Sonstige':
          return '#3c97a0';
      }
    });
    const skillsname = computed(() => {
      switch (openfilter.value) {
        case 'Neuentwicklung':
          return 'neuentwicklung';
        case 'Umsetzung':
          return 'umsetzung';
        case 'Sonstige':
          return 'sonstige';
      }
    });

    return {
      goToSkill,
      skills,
      infoboxes,
      skillscolor,
      skillsname,
      filteredskills,
      openfilter,
    };
  },
});
